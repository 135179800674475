
import { IonInput, IonTextarea } from '@ionic/vue'
import { defineComponent, ref } from 'vue'
import { useBackend } from '@dview/logic'
import { CustomPushNotificationRequest, CustomPushNotificationResponse } from '@dview/shared/backend/pushnotifications-api'

export default defineComponent({
    components: {
        IonInput,
        IonTextarea
    },
    setup() {
        const { pushNotificationService } = useBackend()

        const title = ref('')
        const message = ref('')
        const notificationResult = ref<CustomPushNotificationResponse>(null!)

        const placeholderMessageExample = 'Changes:\n' +
        '- Ability to thwart any deviation\n' +
        '- Bugfixes and performance improvements'

        async function sendNotification() {
            const request: CustomPushNotificationRequest = {
                message: message.value,
            }

            if (title.value.length > 0) {
                request.title = title.value
            }

            const result = await pushNotificationService.sendCustomNotification(request)
            notificationResult.value = result
        }

        return {
            title,
            message,
            placeholderMessageExample,
            notificationResult,
            sendNotification
        }
    },
})
